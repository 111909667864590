<template>
  <div class="grid">
    <Toast />

    <div class="col-12" v-if="clienteData">
      <h6 v-if="clienteData.id">Editando Cliente {{ clienteData.id}}
      <span :class="'ml-3  cliente-badge status-' + (clienteData.status ? clienteData.status.toLowerCase() : '')">{{clienteData.status}}</span>
      </h6>
      <h6 v-else>Novo Cliente</h6>
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Salvar" icon="pi pi-save" class="p-button-success mr-2" @click="saveCliente" :loading="loadingBtnSave" />
            <Button label="Exportar" icon="pi pi-download" class="mr-2 p-button-outlined" @click="exportCSV($event)"  />
            <Button label="Importar" icon="pi pi-upload" class="mr-2 p-button-outlined" @click="exportCSV($event)"  />
            <Button label="Nova Ocorrência" icon="pi pi-plus" class="mr-2 p-button-outlined" @click="novaOcor()"  />
          </div>
        </template>
        <template v-slot:end>
          <Button label="Voltar" icon="pi pi-backward" class="p-button-outlined mr-2" @click="$router.go(-1)" />
        </template>
      </Toolbar>
    </div>

    <div class="col-12" >

      <div  class="custom-skeleton p-4" v-if="loadingData === true">
        <Skeleton width="100%" height="50px"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
      </div>

      <TabView v-if="loadingData === false" :activeIndex="activeIndex">

        <TabPanel>

          <template #header>
            <span>Dados Principais</span>
            <i class="pi pi-user ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid">

            <div class="field col-3 md:col-3">
              <label for="nome">Nome</label>
              <InputText id="firstname2" type="text" v-model="clienteData.nome"/>
            </div>

            <div class="field col-3 md:col-3" v-if="clienteData.tipo === 'PF'">
              <label for="cnpj">CPF</label>
              <div class="p-inputgroup">

                <InputMask id="cnpj" mask="999.999.999-99" v-model="clienteData.cnpj"></InputMask>
<!--                <Button label="Pesquisar CPF" :loading="loadingBtn[1]" @click="loadBtn(1)"/>-->
              </div>
            </div>

            <div class="field col-3 md:col-3" v-if="clienteData.tipo === 'PJ'">
              <label for="cnpj">CNPJ</label>
              <div class="p-inputgroup">

                <InputMask id="cnpj" mask="99.999.999/9999-99" v-model="clienteData.cnpj"></InputMask>
<!--                <Button label="Pesquisar CNPJ" :loading="loadingBtn[1]" @click="loadBtn(1)"/>-->
              </div>
            </div>


            <div class="field col-3 md:col-3">
              <label for="tel1">Telefone</label>
              <div class="p-inputgroup">
                <InputMask id="tel1" mask="(99) 9.9999-9999" v-model="clienteData.tel1"></InputMask>
                <Button icon="pi pi-whatsapp" class="p-button" @click="enviarZap()"/>
              </div>
            </div>


            <div class="field col-3 md:col-3">
              <label for="email">E-mail</label>
              <div class="p-inputgroup">
                <InputText id="lastname2" type="text" v-model="clienteData.email"/>
                <Button icon="pi pi-send" class="p-button" @click="enviarEmail()"/>
              </div>
            </div>


            <div class="field col-3 md:col-3">
              <label for="tipo">Tipo Pessoa</label>
              <Dropdown id="tipo" v-model="clienteData.tipo" :options="tipoItems" optionLabel="name"  optionValue="code" placeholder="Selecione o tipo de pessoa"></Dropdown>
            </div>

            <div class="col-12" >
              <h5>Endereço</h5>
            </div>

          <div class="field col-3 md:col-3">
            <label for="cep">CEP</label>
            <div class="p-inputgroup">
              <InputMask id="tel1" mask="99.999-999" v-model="clienteData.cep"></InputMask>
              <Button label="Pesquisar CEP" :loading="loadingCEP" @click="pesquisarCEP()"/>
            </div>
          </div>

            <div class="field col-3 md:col-3">
              <label for="logradouro">Logradouro</label>
              <InputText id="logradouro" type="text" v-model="clienteData.rua"/>
            </div>
            <div class="field col-3 md:col-3">
              <label for="numero">Número</label>
              <InputText id="numero" type="text" v-model="clienteData.numero"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="bairro">Bairro</label>
              <InputText id="bairro" type="text" v-model="clienteData.bairro"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="cidade">Cidade</label>
              <AutoComplete placeholder="Pesquisar cidade" :dropdown="true" :multiple="false" v-model="selectedAutoValueCidade" :suggestions="autoFilteredCidade" @complete="searchCidade($event)" field="nome" @item-select="selecionaCidade($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 60%">{{slotProps.item.nome}}</div>
                    <div style="width: 20%" class="text-right">{{ formatDecimal(slotProps.item.anual) }}</div>
                    <div style="width: 10%"  class="text-right">{{slotProps.item.estado}}</div>
                  </div>
                </template>
              </AutoComplete>
            </div>

            <div class="field col-3 md:col-3">
              <label for="compl">Complemento</label>
              <InputText id="compl" type="text" v-model="clienteData.compl"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="estado">Estado</label>
              <Dropdown id="estado" v-model="clienteData.estado" :options="estadoItems" optionLabel="name"  optionValue="code" placeholder="Selecionar estado"></Dropdown>
            </div>

            <div class="field col-12">
              <label for="observacao">Observações</label>
              <Textarea id="observacao" rows="4" v-model="clienteData.observacao"/>
            </div>

          </div>
        </TabPanel>

        <TabPanel>

          <template #header>
            <span>Local</span>
            <i class="pi pi-box ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid" v-if="clientecadData">

            <Divider layout="horizontal">
              <b>Informações do Local de instalação</b>
              <i class="pi pi-map ml-2"></i>
            </Divider>


            <div class="field col-12 md:col-12">
              <h5>Grupo</h5>

              <div class="grid mt-2">
                <div class="col-2 md:col-2">
                  <div class="field-radiobutton">
                    <RadioButton id="option1" name="option" value="A" v-model="localData.grupo" />
                    <label for="option1">A</label>
                  </div>
                </div>
                <div class="col-2 md:col-2">
                  <div class="field-radiobutton">
                    <RadioButton id="option2" name="option" value="B1" v-model="localData.grupo" />
                    <label for="option2">B1</label>
                  </div>
                </div>
                <div class="col-2 md:col-2">
                  <div class="field-radiobutton">
                    <RadioButton id="option3" name="option" value="B2" v-model="localData.grupo" />
                    <label for="option3">B2</label>
                  </div>
                </div>
                <div class="col-2 md:col-2">
                  <div class="field-radiobutton">
                    <RadioButton id="option3" name="option" value="B3" v-model="localData.grupo" />
                    <label for="option3">B3</label>
                  </div>
                </div>
                <div class="col-2 md:col-2">
                  <div class="field-radiobutton">
                    <RadioButton id="option3" name="option" value="B4" v-model="localData.grupo" />
                    <label for="option3">B4</label>
                  </div>
                </div>
              </div>


            </div>

            <div class="field col-12 md:col-3">
              <label for="sistema">Sistema</label>
              <Dropdown id="sistema" v-model="localData.sistema" :options="sistemaItems" optionLabel="name" optionValue="code" placeholder="Selecione o sistema"  ></Dropdown>
            </div>

            <div class="field col-12 md:col-3">
              <label for="tensao">Tensão</label>
              <Dropdown id="tensao" v-model="localData.tensao" :options="tensaoItems" optionLabel="name" optionValue="code" placeholder="Selecione a tensão"  ></Dropdown>
            </div>


            <div class="field col-12 md:col-3">
              <label for="numinst">Número da Instalação</label>
              <InputText id="numinst" v-model="localData.numinst"/>
            </div>

            <div class="field col-12 md:col-3">
              <label for="nummed">Número do Medidor</label>
              <InputText id="nummed" v-model="localData.nummed"/>
            </div>


            <div class="field col-6 md:col-6 mt-3">
              <h5>Aumento de consumo</h5>
              <div class="grid mt-2">
                <div class="col-6 md:col-6">
                  <div class="field-radiobutton">
                    <RadioButton id="option2" name="option" value="Sim" v-model="localData.aumento" />
                    <label for="option2">Sim (Situar novos materiais de alta potência. Exemplo: arcondicionado, chuveiro,etc.)</label>
                  </div>
                </div>
                <div class="col-6 md:col-6">
                  <div class="field-radiobutton">
                    <RadioButton id="option2" name="option" value="Não" v-model="localData.aumento" />
                    <label for="option2">Não</label>
                  </div>
                </div>
              </div>
            </div>


            <div class="field col-6 md:col-6 mt-3">
              <h5>Rede de internet Wifi</h5>
              <div class="grid mt-2">
                <div class="col-6 md:col-6">
                  <div class="field-radiobutton">
                    <RadioButton id="option2" name="option" value="Sim" v-model="localData.redewifi" />
                    <label for="option2">Sim, onde será instalado o inversor</label>
                  </div>
                </div>
                <div class="col-6 md:col-6">
                  <div class="field-radiobutton">
                    <RadioButton id="option2" name="option" value="Nao" v-model="localData.redewifi" />
                    <label for="option2">Não</label>
                  </div>
                </div>
              </div>
            </div>


            <div class="field col-6 md:col-6 mt-3">
              <h5>Espaço de armazenagem (Para alocação do material)</h5>
              <div class="grid mt-2">
                <div class="col-6 md:col-6">
                  <div class="field-radiobutton">
                    <RadioButton id="option2" name="option" value="Sim" v-model="localData.armazena" />
                    <label for="option2">Sim</label>
                  </div>
                </div>
                <div class="col-6 md:col-6">
                  <div class="field-radiobutton">
                    <RadioButton id="option2" name="option" value="Nao" v-model="localData.armazena" />
                    <label for="option2">Não</label>
                  </div>
                </div>
              </div>
            </div>


            <div class="field col-6 md:col-6 mt-3">
              <h5>Coordenadas</h5>
              <div class="grid mt-2">
                <div class="col-6 md:col-6">
                  <div class="field-radiobutton">
                    <InputText v-model="localData.lat" placeholder="latitude"/>
                  </div>
                </div>
                <div class="col-6 md:col-6">
                  <div class="field-radiobutton">
                    <InputText v-model="localData.long" placeholder="longitude"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="field col-12 md:col-3">
              <label for="telhado">Tipo de Telhado</label>
              <Dropdown id="telhado" v-model="localData.tipotelha" :options="telhadoItems" optionLabel="name" optionValue="code" placeholder="Selecione o tipo de telhado"  ></Dropdown>
            </div>

            <div class="field col-12 md:col-3">
              <label for="telhoutro"  v-if="localData.tipotelha === 'OUT'">Especificar tipo de telhado</label>
              <InputText id="telhoutro" v-model="localData.tipteloutro"   v-if="localData.tipotelha === 'OUT'" />
            </div>



            <div class="field col-12 md:col-3">
              <label for="telhestr">Estrutura do Telhado</label>
              <Dropdown id="telhestr" v-model="localData.estrtelha" :options="telhestrItems" optionLabel="name" optionValue="code" placeholder="Selecione a estrutura do telhado"  ></Dropdown>
            </div>

            <div class="field col-12 md:col-3">
              <label for="telhoutro"  v-if="localData.estrtelha === 'OUT'">Especificar estrutura de telhado</label>
              <InputText id="telhoutro" v-model="localData.estrteloutro"  v-if="localData.estrtelha === 'OUT'" />
            </div>

            <div class="field col-12 md:col-3">
              <label for="telharea">Área Útil do Telhado</label>
              <InputNumber id="telharea" v-model="localData.areatelha" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>

            <div class="field col-12 md:col-3">
              <label for="gerador">Possui Gerador</label>
              <Dropdown id="gerador" v-model="localData.gerador" :options="geradorItems" optionLabel="name" optionValue="code" placeholder="Selecionar sim se possuir gerador"  ></Dropdown>
            </div>

            <div class="field col-12 md:col-3">
              <label for="telhorie">Orientação do Telhado</label>
              <Dropdown id="telhorie" v-model="localData.oritelha" :options="telhorieItems" optionLabel="name" optionValue="code" placeholder="Selecionar a orientação do telhado"  ></Dropdown>
            </div>


            <div class="field col-12 md:col-3">
              <label for="telhqtdabas">Quantidade de abas do telhado</label>
              <InputNumber id="telhqtdabas" v-model="localData.abastelha" locale="pt-BR"  />
            </div>

            <div class="field col-12 md:col-12">
              <label for="telhacess" >Maneira de acesso ao telhado</label>
              <InputText id="telhacess" v-model="localData.acetelha" placeholder="Exemplo: Com escada na área externa"/>
            </div>


            <div class="field col-12">
              <label for="obs">Informações Adicionais</label>
              <Textarea id="obs" rows="4" v-model="localData.obs" />
            </div>

            <Divider layout="horizontal">
              <b>Anexos</b>
              <i class="pi pi-file ml-2"></i>
            </Divider>


            <div class="field col-12 md:col-3">
              <h6>PDF Conta de Energia</h6>
              <FileUpload @click="arqSubindo = 'conta_energia'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqContaEnergia" @click="excluirFile(arqContaEnergia)"  style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqContaEnergia" @click="downloadFile(arqContaEnergia.url)"  style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Foto do Relógio</h6>
              <FileUpload @click="arqSubindo = 'relogio'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqRelogio" @click="excluirFile(arqRelogio)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqRelogio" @click="downloadFile(arqRelogio.url)" style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Quadro de Distribuição</h6>
              <FileUpload @click="arqSubindo = 'quadro_distribuicao'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqQuadroDistribuicao" @click="excluirFile(arqQuadroDistribuicao)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqQuadroDistribuicao" @click="downloadFile(arqQuadroDistribuicao.url)" style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Disjuntor Geral</h6>
              <FileUpload @click="arqSubindo = 'disjuntor_geral'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqDisjuntorGeral" @click="excluirFile(arqDisjuntorGeral)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqDisjuntorGeral" @click="downloadFile(arqDisjuntorGeral.url)" style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Telhado</h6>
              <FileUpload @click="arqSubindo = 'telhado'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqTelhado" @click="excluirFile(arqTelhado)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqTelhado" @click="downloadFile(arqTelhado.url)" style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Faixada da Casa</h6>
              <FileUpload @click="arqSubindo = 'faixada'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqFaixada" @click="excluirFile(arqFaixada)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqFaixada" @click="downloadFile(arqFaixada.url)" style="width: 50%;"/>
            </div>

          </div>

        </TabPanel>



        <TabPanel>

          <template #header>
            <span>Ocorrências</span>
            <i class="pi pi-exclamation-circle ml-2"></i>
          </template>

          <DataTable v-if="ocorsData" :value="ocorsData" :scrollable="true" scrollHeight="400px" :loading="loadingOcors" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="slotProps">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="abrirOcor(slotProps.data.id)" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.created_at)}}
              </template>
            </Column>
<!--            <Column field="tipo" header="Tipo" :style="{width:'200px'}"></Column>-->

            <Column field="tipo" header="Tipo" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge tipo-' + (data.tipo ? data.tipo.toLowerCase() : '')">{{data.tipo}}</span>
              </template>
            </Column>

            <Column field="obs" header="Observação" :style="{width:'300px'}" ></Column>

            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge status-' + (data.status ? data.status.toLowerCase() : '')"> {{ data.status }} </span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>


          </DataTable>


        </TabPanel>


        <TabPanel>

          <template #header>
            <span>Leads</span>
            <i class="pi pi-id-card ml-2"></i>
          </template>

          <DataTable v-if="leadsData" :value="leadsData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/leads')" />
              </template>
            </Column>
            <Column field="code" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column field="representante" header="Representante" :style="{width:'200px'}"></Column>
            <Column field="inventoryStatus" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'lead-badge status-' + (data.inventoryStatus ? data.inventoryStatus.toLowerCase() : '')">{{data.inventoryStatus}}</span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.price)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>


        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Orçamentos</span>
            <i class="pi pi-eye ml-2"></i>
          </template>

          <DataTable v-if="orcamentosData" :value="orcamentosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/orcamentodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'orcamento-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Vendas</span>
            <i class="pi pi-shopping-cart ml-2"></i>
          </template>

          <DataTable v-if="pedidosData" :value="pedidosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/pedidodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'pedido-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


        <TabPanel>

          <template #header>
            <span>Obras</span>
            <i class="pi pi-briefcase ml-2"></i>
          </template>

          <DataTable v-if="obrasData" :value="obrasData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/obradetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'obra-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>



        <TabPanel>

          <template #header>
            <span>Projetos</span>
            <i class="pi pi-sitemap ml-2"></i>
          </template>


          <DataTable v-if="projetosData" :value="projetosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/projetodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'projeto-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>


        </TabPanel>


<!--        <TabPanel>-->

<!--          <template #header>-->
<!--            <span>Financeiro</span>-->
<!--            <i class="pi pi-money-bill ml-2"></i>-->
<!--          </template>-->
<!--          Em andamento-->
<!--        </TabPanel>-->
      </TabView>
    </div>

<!--    <div class="col-12">-->
<!--      <cliente-detalhe-timeline></cliente-detalhe-timeline>-->
<!--    </div>-->


    <Sidebar v-model:visible="sidebarOcorrencia" :baseZIndex="1000" position="right" class="p-sidebar-md">
      <h5 v-if="!ocorrenciaData.id">Nova Ocorrência</h5>
      <h5 v-if="ocorrenciaData.id">Editando Ocorrência {{ ocorrenciaData.id }}</h5>


      <div class="grid p-fluid mt-3" >
        <div class="col-12 md:col-12">
          <label for="tipoocor">Tipo</label>
          <div class="p-inputgroup">

            <Dropdown id="tipoocor"
                      v-model="ocorrenciaData.tipo"
                      :options="tipoocorItems"
                      optionLabel="name"
                      optionValue="code"
                      :class="validOcor && !ocorrenciaData.tipo ? 'p-invalid' : ''"
                      placeholder="Selecione o tipo">
            </Dropdown>
            <small class="p-invalid" v-if="validOcor && !ocorrenciaData.tipo">Campo obrigatório.</small>

          </div>
        </div>

        <div class="col-12 md:col-12" >
          <label for="obsocor">Observações</label>
          <div class="p-inputgroup">

            <Textarea id="obsocor"
                      rows="4"
                      :class="validOcor && !ocorrenciaData.obs ? 'p-invalid' : ''"
                      v-model="ocorrenciaData.obs" />

            <small class="p-invalid" v-if="validOcor && !ocorrenciaData.obs">Campo obrigatório.</small>
          </div>
        </div>

        <div class="col-6 md:col-6" v-if="ocorrenciaData.id">
          <Button label="Excluir" icon="pi pi-trash" class="mr-2 p-button-danger p-button-outlined" @click="excluirOcor()"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Fechar" icon="pi pi-ban" class="mr-2 p-button-outlined" @click="sidebarOcorrencia = false; validOcor = false;"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Salvar" icon="pi pi-save" class="mr-2 p-button-outlined" @click="saveOcorrencia()" :loading="loadingBtnSaveOcor" />
        </div>




      </div>
    </Sidebar>


    <ConfirmDialog group="dialog" />



  </div>
</template>
<script>
// import ClienteDetalheTimeline from "@/components_esol/clientes/ClienteDetalheTimeline";
import ClienteService from "@/service/ClienteService";
import api from "@/axios";

export default {
  components: {
      // ClienteDetalheTimeline
  },
  data() {
    return {
      activeIndex: 0,
      loadingBtnSaveOcor: false,
      validOcor: false,
      sidebarOcorrencia: false,
      ocorrenciaData: {},

      arqSubindo: '',
      arqFaixada: null,
      arqTelhado: null,
      arqDisjuntorGeral: null,
      arqQuadroDistribuicao: null,
      arqRelogio: null,
      arqContaEnergia: null,
      arquivos: [],
      tipoUpload: '',
      baseUrl: '',

      tipoocorItems: [
        {code: 'AV', name: 'Aviso'},
        {code: 'CO', name: 'Confirmação'},
        {code: 'PR', name: 'Problema'},
      ],

      telhorieItems: [
        {code: 'NO', name: 'Norte'},
        {code: 'SU', name: 'Sul'},
        {code: 'LE', name: 'Leste'},
        {code: 'OE', name: 'Oeste'},
        {code: 'ND', name: 'Nordeste'},
        {code: 'SD', name: 'Sudoeste'},
        {code: 'NR', name: 'Noroeste'},

      ],
      geradorItems: [
        {code: 'SIM', name: 'Sim'},
        {code: 'NAO', name: 'Não'},

      ],
      telhestrItems: [
        {code: 'MAD', name: 'Madeira'},
        {code: 'MET', name: 'Metal'},
        {code: 'TER', name: 'Terça'},
        {code: 'OUT', name: 'Outro'},
      ],
      telhadoItems: [
        {code: 'FIB', name: 'Fibrocimento'},
        {code: 'COL', name: 'Colonial'},
        {code: 'ZIN', name: 'Zinco'},
        {code: 'OUT', name: 'Outro'},
      ],
      tensaoItems: [
        {code: '127', name: '127V'},
        {code: '220', name: '220V'},
        {code: '380', name: '380V'},
      ],
      sistemaItems: [
        {code: 'MN', name: 'Monofásico'},
        {code: 'BI', name: 'Bifásico'},
        {code: 'TR', name: 'Trifásico'},
      ],
      radioValueEspaco: null,
      radioValueAumento: null,
      radioValueInternet: null,
      radioValueGrupo: null,
      clientecadData: {
        'perfil': 'coisa'
      },
      selectedAutoValueCidade: null,
      autoFilteredCidade: [],
      autoValueCidade: [],
      loadingCEP: false,
      loadingData: true,
      loadingBtnSave: false,
      loadingBtn: [false, false, false],
      pedidosData: null,
      orcamentosData: null,
      obrasData: null,
      projetosData: null,

      loadingOcors: null,
      ocorsData: null,

      loadingLeads: true,
      leadService: null,
      leadsData: null,
      clienteData: null,
      localData: null,
      tipoItems: [
        {code: 'PF', name: 'Pessoa Física'},
        {code: 'PJ', name: 'Pessoa Jurídica'},
      ],

      estadoItems: [
        {code: 'AC', name: 'Acre'},
        {code: 'AL', name: 'Alagoas'},
        {code: 'AP', name: 'Amapá'},
        {code: 'AM', name: 'Amazonas'},
        {code: 'BA', name: 'Bahia'},
        {code: 'CE', name: 'Ceará'},
        {code: 'DF', name: 'Distrito Federal'},
        {code: 'ES', name: 'Espírito Santo'},
        {code: 'GO', name: 'Goiás'},
        {code: 'MA', name: 'Maranhão'},
        {code: 'MT', name: 'Mato Grosso'},
        {code: 'MS', name: 'Mato Grosso do Sul'},
        {code: 'MG', name: 'Minas Gerais'},
        {code: 'PA', name: 'Pará'},
        {code: 'PB', name: 'Paraíba'},
        {code: 'PR', name: 'Paraná'},
        {code: 'PE', name: 'Pernambuco'},
        {code: 'PI', name: 'Piauí'},
        {code: 'RJ', name: 'Rio de Janeiro'},
        {code: 'RN', name: 'Rio Grande do Norte'},
        {code: 'RS', name: 'Rio Grande do Sul'},
        {code: 'RO', name: 'Rondônia'},
        {code: 'RR', name: 'Roraima'},
        {code: 'SC', name: 'Santa Catarina'},
        {code: 'SP', name: 'São Paulo'},
        {code: 'SE', name: 'Sergipe'},
        {code: 'TO', name: 'Tocantins'},
      ],
      dropdownItem: null,
    }
  },

  created() {
    this.clienteService = new ClienteService();

  },


  mounted() {

    console.log('mounted clidetalhe')
    console.log(api.getUri())
    this.baseUrl = api.getUri() + 'api/ssuploadfile'


    let idcliente = this.$route.params.id
    this.clienteService.getsscliente(idcliente).then((data) => {
      this.clienteData = data[0]
      this.localData = data[2]
      this.ocorrenciaData.pessoa_id = this.clienteData.id

      console.log('getCliente')
      console.log(data)

      if (data === 'nao_permitido') {
        this.$router.replace({ path: '/acessorestrito' })
      }else{
        this.loadingData = false
        this.selectedAutoValueCidade = this.clienteData.cidade
        if (!this.localData.lat || !this.localData.long) {
          navigator.geolocation.getCurrentPosition(pos => {
            // console.log(pos)
            this.localData.lat = pos.coords.latitude
            this.localData.long = pos.coords.longitude
          }, err => {
            this.errorStr = err.message
          })
        }

        this.atualizaListaImages()

        this.$router.replace({ path: '/clientedetalhe/' + this.clienteData.id })
      }
    })

    this.getOcorrencias(idcliente)

    // this.clienteService.getsscliocors(idcliente).then((data) => {
    //   console.log('this.ocorsData')
    //   this.ocorsData = data
    //   this.loadingOcors = false
    //   console.log(this.ocorsData)
    //   this.ocorsData.forEach(ocor => ocor.created_at = new Date(ocor.created_at));
    // });

    this.clienteService.getLeads().then((data) => {
      console.log('clienteDetalhe_0')
      console.log(data)
      this.leadsData = []
      this.loadingLeads = false
      // console.log(this.leadsData)
      this.leadsData.forEach(lead => lead.date = new Date(lead.date));
    });

    this.clienteService.getOrcamentos().then((data) => {
      console.log(data)
      this.orcamentosData = []
      // console.log(this.orcamentosData)
      this.orcamentosData.splice(0,6)
      // console.log(this.orcamentosData)
      this.orcamentosData.forEach(orcto => orcto.date = new Date(orcto.date));
    });

    this.clienteService.getPedidos().then((data) => {
      console.log(data)
      this.pedidosData = []
      // console.log(this.pedidosData)
      this.pedidosData.splice(0,3)
      // console.log(this.pedidosData)
      this.pedidosData.forEach(pedido => pedido.date = new Date(pedido.date));
    });

    this.clienteService.getObras().then((data) => {
      console.log(data)
      this.obrasData = []
      // console.log(this.pedidosData)
      this.obrasData.splice(0,5)
      // console.log(this.pedidosData)
      this.obrasData.forEach(obra => obra.date = new Date(obra.date));
    });

    this.clienteService.getProjetos().then((data) => {
      console.log(data)
      this.projetosData = []
      // console.log(this.pedidosData)
      this.projetosData.splice(0,8)
      // console.log(this.pedidosData)
      this.projetosData.forEach(obra => obra.date = new Date(obra.date));
    });



  },

  methods: {

    getOcorrencias(idcliente){
      this.clienteService.getsscliocors(idcliente).then((data) => {
        console.log('this.ocorsData')
        this.ocorsData = data
        this.loadingOcors = false
        console.log(this.ocorsData)
        this.ocorsData.forEach(ocor => ocor.created_at = new Date(ocor.created_at));
      });
    },

    excluirOcor(){
      console.log('excluirOcor')

      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o registro?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          this.clienteService.ssdeleteocor(this.ocorrenciaData.id).then(() => {
            this.$toast.add({severity:'success', summary: 'Registro excluído', detail:'As informações foram excluídas com sucesso', life: 3000});
            this.sidebarOcorrencia = false
            this.getOcorrencias(this.clienteData.id)
          })
        },
        reject: () => {

        }
      });


    },

    abrirOcor(idOcor){
      console.log('abrirOcor')
      console.log(idOcor)
      this.clienteService.getsscliocor(idOcor).then((data) => {
        console.log('this.ocorData')
        //console.log(data[0])
        this.ocorrenciaData = data[0]
        this.sidebarOcorrencia = true
      });
    },

    saveOcorrencia() {
      console.log('sssaveocor')
      // console.log(this.ocorrenciaData)
      this.validOcor = true
      this.loadingBtnSaveOcor = true
      if (this.ocorrenciaData.tipo && this.ocorrenciaData.obs){
        this.clienteService.savessocor(this.ocorrenciaData).then(() => {
          // console.log('savessocor')
          // console.log(dataOcor)
          this.loadingBtnSaveOcor = false
          this.sidebarOcorrencia = false
          this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});


          this.getOcorrencias(this.clienteData.id)
          // this.clienteService.getsscliocors(this.clienteData.id).then((data) => {
          //   console.log('this.ocorsData')
          //   this.ocorsData = data
          //   this.loadingOcors = false
          //   console.log(this.ocorsData)
          //   this.ocorsData.forEach(ocor => ocor.created_at = new Date(ocor.created_at));
          // });

        });
      }else{
        this.loadingBtnSaveOcor = false
      }
    },

    novaOcor(){
      console.log('novaOcor')
      this.ocorrenciaData = {
        'pessoa_id': this.clienteData.id
      }
      this.validOcor = false
      this.sidebarOcorrencia = true
      this.activeIndex = 2

    },

    excluirFile(arquivo){
      console.log('excluirFile')
      console.log(arquivo.id)

      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o arquivo?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          console.log('confirmou')

          this.clienteService.ssdeletefile(arquivo.id).then((data) => {
            console.log('data delete file')
            console.log(data)


            if (arquivo.tipo === 'conta_energia'){
              this.arqContaEnergia = null
            } else if (arquivo.tipo === 'relogio'){
              this.arqRelogio = null
            } else if (arquivo.tipo === 'quadro_distribuicao'){
              this.arqQuadroDistribuicao = null
            } else if (arquivo.tipo === 'disjuntor_geral'){
              this.arqDisjuntorGeral = null
            } else if (arquivo.tipo === 'telhado'){
              this.arqTelhado = null
            } else if (arquivo.tipo === 'faixada'){
              this.arqFaixada = null
            }


            this.$toast.add({severity:'success', summary: 'Arquivo excluído', detail:'As informações foram excluídas com sucesso', life: 3000});

          })


        },
        reject: () => {

        }
      });
    },


    downloadFile(arquivo){
      console.log('downloadFile')
      console.log(arquivo)

      window.open(api.getUri() + 'uploads/'+ arquivo, '_blank');
    },

    atualizaListaImages(){
      this.clienteService.sspeslocimg(this.localData.id).then((data) => {
        // console.log('images')
        // console.log(data)
        data.forEach(arquivo => {
          if (arquivo.tipo === 'conta_energia'){
            this.arqContaEnergia = arquivo
          } else if (arquivo.tipo === 'relogio'){
            this.arqRelogio = arquivo
          } else if (arquivo.tipo === 'quadro_distribuicao'){
            this.arqQuadroDistribuicao = arquivo
          } else if (arquivo.tipo === 'disjuntor_geral'){
            this.arqDisjuntorGeral = arquivo
          } else if (arquivo.tipo === 'telhado'){
            this.arqTelhado = arquivo
          } else if (arquivo.tipo === 'faixada'){
            this.arqFaixada = arquivo
          }
        })
        this.arquivos = data
      })
    },

    beforeUpload(request){
      console.log('beforeUpload')
      //console.log(tipo)

      request.formData.append('origem', 'pessoaLocal')
      request.formData.append('origemId', this.localData.id)
      request.formData.append('tipo', this.arqSubindo)

      const token = localStorage.getItem("token");

      if (token) {
        //config.headers.Authorization = `Bearer ${token}`;
        request.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      }

      //console.log(event.formData)
      return request
    },

    onUpload() {
      console.log('onUpload')
      this.atualizaListaImages()
      this.$toast.add({severity:'success', summary: 'Arquivo gravado', detail:'As informações foram atualizadas com sucesso', life: 3000});

    },

    selecionaCidade(event){
      console.log('selecionaCliente')
      // console.log(event.value)
      this.clienteData['cidade'] = event.value.nome
      this.clienteData['estado'] = event.value.estado
    },

    searchCidade(event){
      console.log('searchCidade')
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCidade = [...this.autoValueCidade];
        }
        else {
          // console.log('vai pesquisar')
          this.clienteService.getPesqCidade().then((data) => {
            // console.log('getPesqCidade')
            // console.log(data)
            this.autoValueCidade = data
            this.autoFilteredCidade = this.autoValueCidade.filter((cidade) => {
              return cidade.nome.toLowerCase().startsWith(event.query.toLowerCase());
            });
          });

        }
      }, 250);
    },

    pesquisarCEP() {
      if (!this.clienteData.cep) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de cep inválido, favor corrigir', life: 3000});
      }else{
        this.loadingCEP = true
        let cepFormated = this.clienteData.cep.replace('.','').replace('-', '');
        this.clienteService.getPesquisaCEP(cepFormated).then((dataCEP) => {
          console.log('getPesquisaCEP')
          console.log(dataCEP)

          if (dataCEP.cep){
            this.clienteData.bairro = dataCEP.bairro
            this.clienteData.compl = dataCEP.complemento
            this.clienteData.cidade = dataCEP.localidade
            this.selectedAutoValueCidade = dataCEP.localidade
            this.clienteData.rua = dataCEP.logradouro
            this.clienteData.estado = dataCEP.uf
          }else{
            this.$toast.add({severity:'warn', summary: 'Validação', detail:'Retorno de consulta inválido, favor preencher manualmente os dados', life: 3000});
          }
          this.loadingCEP = false
        });
      }


    },

    enviarEmail(){
      if (!this.clienteData.email) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de e-mail inválido, favor corrigir', life: 3000});
      }else{
        window.open("mailto:"+this.clienteData.email);
      }
    },

    enviarZap(){
      if (!this.clienteData.telefone) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Tamanho do número de telefone inválido, favor corrigir', life: 3000});
      }else{
        window.open('https://api.whatsapp.com/send?phone=5527999722245', '_blank');
      }
    },

    loadBtn(index) {
      this.loadingBtn[index] = true;
      setTimeout(() => this.loadingBtn[index] = false, 1000);
    },

    saveCliente() {
      this.loadingBtnSave = true
      this.clienteService.savesscliente({
        'cliente': this.clienteData,
        'local': this.localData
      }).then((dataCli) => {
        console.log('savesscliente')
        console.log(dataCli)
        this.loadingBtnSave = false
        this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});

        // this.orcamentoData = data
      });

    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },

    formatCurrency(value) {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
